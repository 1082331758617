import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

const MenuCustomBurger = ({ menuData, customClass }) => {

  const [activeMenus, setActiveMenus] = useState([]);

  const handleArrowClick = menuName => {console.log(activeMenus);
    let newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuName)) {
      var index = newActiveMenus.indexOf(menuName);
      if (index > -1) {
        newActiveMenus.splice(index, 1);
      }
    } else {
      newActiveMenus.push(menuName);
    }

    setActiveMenus(newActiveMenus);
  };

  const ListMenu = ({ dept, data, hasSubMenu, menuName, menuIndex }) => (
    <li>
      <div style={hasSubMenu? {display: "flex", justifyContent: "center"} : {}}>
        <a
          style={
            dept === 1? {color: "#000", fontWeight: "bolder"} : dept === 2 ? {color: "#444", fontWeight: "bold"} : {color: "#888"/*, fontSize: "14px"*/}
          }
          href={data.url}
          target={data.is_external ? '_blank' : '_self'}
          rel={data.is_external ? 'noopener noreferrer' : ''}
        >
          {data.name}
        </a>

        {hasSubMenu && (
          <span
            className={activeMenus.includes(menuName)? "burger-submenu burger-submenu-button-close" : "burger-submenu burger-submenu-button-open"}
            onClick={() => handleArrowClick(menuName)}
            /*toggle={activeMenus.includes(menuName)}*/
          ></span>
        )}
      </div>
      {hasSubMenu && (
        <SubMenu
          dept={dept}
          data={data.children}
          menuIndex={menuIndex}
          parentMenuName={menuName}
        />
      )}
    </li>
  );

  const SubMenu = ({ dept, data, parentMenuName, menuIndex }) => {
    if (!activeMenus.includes(parentMenuName)) {
      return null;
    }

    dept = dept + 1;

    return (
      <ul>
        {data.map((menu, index) => {
          const menuName = `burger-submenu-${dept}-${menuIndex}-${index}`;

          return (
            <ListMenu
              dept={dept}
              data={menu}
              hasSubMenu={menu.children.length > 0}
              menuName={menuName}
              key={menuName}
              menuIndex={index}
            />
          );
        })}
      </ul>
    );
  };

  return (
    <ul className={customClass}>
      {menuData.map((menu, index) => {
        const dept = 1;
        const menuName = `burger-menu-${dept}-${index}`;

        return (
          <ListMenu
            dept={dept}
            data={menu}
            hasSubMenu={menu.children.length > 0}
            menuName={menuName}
            key={menuName}
            menuIndex={index}
          />
        );
      })}
    </ul>
  );
};

export default MenuCustomBurger;
