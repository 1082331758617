import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";

const MenuCustom = ({ menuData, customClass }) => {
  

  const renderSubMenu = (children, level = 1) => {
    //if (!children || level > 3) return null;

    return <ul>
      {children.map((menu) => (
        <li
          key={menu.id}
          className={menu.children.length > 0 ? "submenu-has-child" : ""}
        >

          <a
            href={menu.url}
            target={menu.is_external ? '_blank' : '_self'}
            rel={menu.is_external ? 'noopener noreferrer' : ''}
          >
            {menu.name}
          </a>
          {menu.children.length > 0 && renderSubMenu(menu.children, level++)}
        </li>
      ))}
    </ul>;
  };

  return <ul class={customClass}>
    {menuData.map((menu) => (
      <li
        key={menu.id}
        className={menu.children.length > 0 ? "menu-has-child" : ""}
      >

        <a
          href={menu.url}
          target={menu.is_external ? '_blank' : '_self'}
          rel={menu.is_external ? 'noopener noreferrer' : ''}
        >
          {menu.name}
        </a>
        {menu.children.length > 0 && renderSubMenu(menu.children)}
      </li>
    ))}
  </ul>;
};

export default MenuCustom;
