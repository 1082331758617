import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Tutor-Grid/tutorgrid.css";

const tutors2 = [
  {
    id: 1,
    name: "John Smith",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Experienced Maths tutor specializing in algebra, geometry, and calculus. Master's degree in Mathematics with 10+ years of tutoring experience.",
    expertise: ["Maths", "Physics", "Statistics"],
  },
  {
    id: 2,
    name: "Jane Doe",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "English tutor passionate about literature and essay writing. Degree in English Literature, helping students achieve top grades.",
    expertise: ["English", "Literature", "Essay Writing"],
  },
  {
    id: 3,
    name: "Carlos Garcia",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Science tutor with expertise in Biology and Chemistry. PhD in Biochemistry and a knack for simplifying complex topics.",
    expertise: ["Biology", "Chemistry", "General Science"],
  },
  {
    id: 4,
    name: "Emily Taylor",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Passionate History tutor specializing in modern and ancient history. BA in History and a certified educator with 8 years of experience.",
    expertise: ["History", "Geography", "Politics"],
  },
  {
    id: 5,
    name: "Michael Brown",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Expert in Business Studies and Economics. MBA graduate with extensive teaching experience in business case studies and financial analysis.",
    expertise: ["Business Studies", "Economics", "Finance"],
  },
  {
    id: 6,
    name: "Sophia Wilson",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Languages tutor fluent in Spanish and French. Years of experience helping students develop conversational and written language skills.",
    expertise: ["Spanish", "French", "Language Arts"],
  },
];

const TutorGrid = ({ tutors }) => {
  const navigate = useNavigate();

  return (
    <div className="tutor-grid-container">
      <h2 className="tutor-grid-title">Meet Our Expert Tutors</h2>
      <div className="tutor-grid">
        {tutors.map((tutor) => (
          <div key={tutor.id} className="tutor-card">
            <figure>
              <img
                src={tutor.profile_image}
                alt={`${tutor.firstName} ${tutor.lastName}'s profile`}
                className="tutor-image"
                onClick={() => navigate(`/tutor-detail/${tutor.slug}`)}
              />
            </figure>
            <div className="tutor-card-body">
              <h2
                className="tutor-name"
                onClick={() => navigate(`/tutor-detail/${tutor.slug}`)}
              >
                {`${tutor.firstName} ${tutor.lastName}`}
                <div className="tutor-badge">Tutor</div>
              </h2>
              <p className="tutor-description">{tutor.description}</p>
              <div className="tutor-subjects">
                {tutor.userCategorySubjects.map((subject) => (
                  <div key={subject.subId} className="tutor-subject">
                    {subject._subId.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TutorGrid;
