import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Img, Text } from "components";
// import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";
import { LogoutModal } from "modals/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import {
  currentUserData,
  currentToken,
  changeToken,
} from "redux/reducers/token";
import { changeSnackbar } from "redux/reducers/snackbar";
import globalRequest from "global-modules/globalRequest";
import MenuCustom from "components/Menu/MenuCustom";
import MenuCustomBurger from "components/Menu/MenuCustomBurger";
import API from "tutorEnum";

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [LogoutModalOpen, setLogoutModalOpen] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menu, setMenu] = React.useState([]);
  const userData = useSelector(currentUserData);
  const token = useSelector(currentToken);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    setLogoutModalOpen(true);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    if (
      LogoutModalOpen === true
    ) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [
    LogoutModalOpen,
  ]);

  const getMenus = async () => {

    const response = await globalRequest(
      "get",
      API.COMMON.GET_MENUS,
      {},
      {},
      true,
      true
    );

    if (response.data) {
      const menuGuests = response.data.find(item => item.id === 1);
      setMenu(menuGuests.menuItems);
    }
  };

  React.useEffect(() => {

    getMenus();
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setLogoutModalOpen(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <header className="bg-white_A700 flex md:flex-col flex-row md:gap-5 items-center justify-center md:px-4 w-full fixed top-0 z-[39]">
        <div className="w-full max-w-[1110px] flex justify-between">
          <div className="header-left">
            <Img
              onClick={() => navigate("/")}
              className="my-2.5 object-cover w-[230px] h-[50px]"
              src="images/img_batterseahouse1.png"
              alt="batterseahouse"
            />

          </div>
          <div className="header-right flex items-center md:flex-row-reverse">
            {token ? (
              <Button
                id="profile-button"
                className="flex items-center justify-start min-h-[70px]"
                aria-controls={open ? "profile-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant={"Transparent"}
              >
                <Img
                  src={userData?.profile_image}
                  className=" md:h-auto rounded-[50%] w-10 h-10"
                  alt="unsplashzhvm3xi"
                  type="profile"
                />
                <div className="flex flex-col items-start justify-start sm:hidden mx-3">
                  <Text
                    className="font-normal font-proximanova text-black_900"
                    variant="body2"
                  >
                    {userData?.firstName} {userData?.lastName}
                  </Text>
                </div>
                <Img
                  src="images/img_arrowdown_gray_600.svg"
                  className="h-4 w-4"
                  alt="arrowdown"
                />
              </Button>
            ) : null}
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "profile-button",
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleLogout} className="gap-2 w-[126px]" style={{ minHeight: '56px' }}>
                <Img src="images/img_logout.svg" className="h-5 w-5" alt="lock" />
                <Text className="font-proximanova text-black_900" variant="body2">
                  Logout
                </Text>
              </MenuItem>
            </Menu>
            {token ? null : (
              <>

                <div
                  className={`md:hidden flex flex-row gap-6 items-start justify-start md:ml-[0] ml-auto w-auto sm:w-full mr-5`}
                >
                  {
                    Array.isArray(menu) && <MenuCustom menuData={menu} customClass={"main-navigation"}></MenuCustom>
                  }

                </div>
                <Link
                  to="/login-student"
                  className="cursor-pointer border border-red_900 border-solid font-semibold min-w-[111px] p-[11px] text-base text-center text-red_900 md:hidden"
                  size="md"
                  variant="OutlineRed900"
                >
                  Student Sign In
                </Link>
                <Link
                  to="/login-tutor"
                  className="cursor-pointer border border-red_900 border-solid font-semibold min-w-[111px] p-[11px] text-base text-center text-red_900 mx-4 md:hidden"
                  size="md"
                  variant="OutlineRed900"
                >
                  Tutor Sign In
                </Link>
                <Link
                  to="/signup"
                  className="cursor-pointer border border-red_900 border-solid font-semibold min-w-[111px] text-base text-center p-[11px] bg-red_900 text-white_A700 md:hidden"
                  size="md"
                  variant="FillRed900"
                >
                  Sign Up
                </Link>

                <div>

                  <button
                    className={`hamburger hamburger--slider hidden md:flex ${isOpen ? 'is-active' : ''}`}
                    type="button"
                    onClick={toggleMenu}
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                    </span>
                  </button>

                  <div
                    className={`hidden flex md:flex-col flex-row gap-6 items-start justify-start md:ml-[0] ml-auto w-auto md:w-full ${!isOpen ? "md:hidden"
                      : "md:flex absolute flex-col items-start bg-white z-[99999] right-0 top-full md:w-screen md:h-screen p-4"}`}
                  >

                    {
                      Array.isArray(menu) && <MenuCustomBurger menuData={menu} customClass={"main-navigation-burger-mode"}></MenuCustomBurger>
                    }
                    
                    <Link
                      to="/login-student"
                      className="burger-menu-link cursor-pointer border border-red_900 border-solid font-semibold min-w-[111px] p-[11px] text-base text-center text-red_900"
                      size="md"
                      variant="OutlineRed900"
                    >
                      Student Sign In
                    </Link>
                    <Link
                      to="/login-tutor"
                      className="burger-menu-link cursor-pointer border border-red_900 border-solid font-semibold min-w-[111px] p-[11px] text-base text-center text-red_900 mx-4"
                      size="md"
                      variant="OutlineRed900"
                    >
                      Tutor Sign In
                    </Link>
                    <Link
                      to="/signup"
                      className="burger-menu-link cursor-pointer border border-red_900 border-solid font-semibold min-w-[111px] text-base text-center p-[11px] bg-red_900 text-white_A700"
                      size="md"
                      variant="FillRed900"
                    >
                      Sign Up
                    </Link>
                  </div>

                </div>
              </>
            )}
          </div>
        </div>
      </header>
      {LogoutModalOpen === true ? (
        <LogoutModal
          closepopup={setLogoutModalOpen}
          logoutSuccess={() => {
            setLogoutModalOpen(false);
            dispatch(changeToken());
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: "Logout Successfully",
                type: "success",
              })
            );
            navigate("/");
          }}
        ></LogoutModal>
      ) : null}
    </>
  );
};
Header.defaultProps = {};
export default Header;